import React from 'react';
import styled from '@emotion/styled';
import HatImg from './img';

const HatLink = styled.a`
  text-transform: uppercase;
  font-weight: normal;
  text-decoration: none;
  padding-bottom: 75%;
  font-size: 0.8em;

  .site {
    font-weight: bold;
  }

  @media(min-width: 1000px) {
    font-size: 1em;
  }

  p {
    margin: 0;
  }
`

const Hat = ({hat}) => {
  return (
    <div style={{width: '100%'}}>
      <HatLink href={hat.url} className={'new-hat'} rel="nofollow">
        <HatImg hat={hat}/>
        <p className={'site'}>{hat.siteTitle}</p>
        <p>{hat.title}</p>
      </HatLink>
    </div>
  );
};

export default Hat;
