import React from 'react';
import './App.css';
import {useFetch} from "./hooks/use-fetch";
import Header from './components/shared/header';
import Container from './components/shared/container';
import Gallery from './components/hats/gallery';

const App = () => {
  const url = 'https://cdn.hatcrawler.com/data/data.json';

  const {status, data, error} = useFetch(url);

  const hats = data.hats || [];
  return (
    <div className="app">
      <Header/>
      <main>
        <Container>
        <p className={"disclaimer"}>Disclaimer: Many of this links below are "affiliate" links. Hat Crawler may receive a small portion of any sale completed when using these links.</p>
          {status === 'idle' && (
            <div>Loading...</div>
          )}
          {status === 'error' && <div>{error}</div>}
          {status === 'fetching' && <div className="loading">Loading...</div>}
          {status === 'fetched' && (
            <>
              {hats.length === 0 && <div>No hats found</div>}
              {hats && (<Gallery hats={hats}/>)}
            </>
          )}
        </Container>
      </main>
    </div>
  );
}

export default App;
