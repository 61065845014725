import React from 'react';
import styled from "@emotion/styled";
import Hat from './hat';

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

  @media(min-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
  grid-auto-rows: 1fr;
  column-gap: 1em;
  row-gap: 1em;
  justify-items: center;
  align-items: start;
  padding: 2em 0;
`;

export default ({hats}) => (
  <Gallery>
    {hats.map(hat => <Hat hat={hat} key={hat.id}/>)}
  </Gallery>
)
