import styled from '@emotion/styled';

export default styled('div')`
    margin: 0px auto;
    width: 93%;

    @media(min-width: 1290px) {
      width: 1200px;
    }
`
