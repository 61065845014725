import React from "react";
import styled from "@emotion/styled";

const BGImage = styled.div`
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding-bottom: 75%;
`

const useOriginalImages = ['lids', 'mlbshop', 'mlsstore', 'nflshop', 'nbastore', 'billioncreation', 'lidsdotcom', 'fanaticsdotcom']

// const buildUrl = ({id, siteName, imageHash}) => `https://cdn.hatcrawler.com/images/${siteName}/${id}/${imageHash}_400.jpg`

const buildUrl = ({image}) => {
  if (image.includes('shopify')) {
    const u = new URL(image);
    const params = new URLSearchParams(u.search);
    params.append("width", "400")
    u.search = params.toString();
    return u.toString();
  } else {
    return image;
  }
}

const Img = ({hat}) => {
  // const url = (useOriginalImages.includes(hat.siteName)) ? hat.image : buildUrl(hat);
  const url = buildUrl(hat);
  return <BGImage style={{backgroundImage: `url("${url}")`}}/>
};

export default Img;
