import React from 'react';
import styled from '@emotion/styled';
import Container from './container';
import logo from './hat-crawler.svg'
import styles from './header-styles.module.scss';
import {SocialIcon} from 'react-social-icons';

const Head = styled('header')`
  background-color: #D8D9DA;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ShopLink = styled.a`
  font-size: 2em;
  display: inline-block;
  height: 50px;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
`

const Header = () => (
  <Head>
    <Container>
      <div className={styles['header-container']}>
        <div className={styles.logo}>
          <img src={logo} alt="Hat Crawler" width="100%"/>
        </div>
        <nav>
          <ul>
            <li><SocialIcon url="https://www.facebook.com/hatcrawler" bgColor="#222450"/></li>
            <li><SocialIcon url="https://www.twitter.com/hatcrawler" bgColor="#222450"/></li>
            <li><SocialIcon url="https://www.instagram.com/hatcrawler" bgColor="#222450"/></li>
            {/*<li><SocialIcon url="https://discord.gg/aKrVzJux4D" network="discord" bgColor="#222450"/></li>*/}
            <li><ShopLink href="https://shop.hatcrawler.com">Shop</ShopLink></li>
          </ul>
        </nav>
      </div>
    </Container>
  </Head>
);

export default Header;
